import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
} from "@mui/material";
const axios = require("axios");

export default function CreateUncertinity(props) {
  const [srfObject, createSrfObject] = React.useState({});
  const [standardRangesDataArr, setStandardRangesDataArr] = React.useState([]);

  var refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = (event) => {
    if (srfObject.distribution && srfObject.distribution.split("")[0] == "√") {
      srfObject.formula = Math.sqrt(
        parseInt(srfObject.distribution.trim().split("")[1])
      );
    } else if (
      srfObject.distribution &&
      srfObject.distribution.split("")[0] == "/"
    ) {
      srfObject.formula = srfObject.distribution.trim().split("")[1];
    }

    let row = {
      name: srfObject.name,
      distribution: srfObject.distribution.trim(),
      formula: srfObject.formula,
      sensitives: srfObject.sensitives,
      linkedRanges: srfObject.linkedRanges,
      defaultValue: srfObject.defaultValue,
    };

    let url = BASE_URL;
    axios
      .post(url + "uncertainty", row)
      .then((res) => {
        props.setLoader(false);
        toast("Uncertainty created successfully !");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };
  const getStandardRangesData = (id) => {
    let url = BASE_URL;
    axios
      .get(url + "standardRanges")
      .then((res) => {
        var keys = [];
        Object.assign(res.data[0], { leastCount: "00" });

        for (var k in res.data[0]) {
          if (
            k != "id" &&
            k !== "standardId" &&
            k !== "rangeName" &&
            k !== "status" &&
            k !== "lastModified"
          )
            keys.push({ name: k });
        }

        setStandardRangesDataArr(keys);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };

  useEffect(() => {
    getStandardRangesData();
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create New Uncertainty
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Name *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("name", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Distribution *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("distribution", e.target.value);
            }}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Sensitive Coefficient *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("sensitives", e.target.value);
            }}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={standardRangesDataArr.map(({ id, name }) => ({
              label: name,
            }))}
            renderInput={(params) => (
              <TextField {...params} label="Master Link *" />
            )}
            onInputChange={(event, newInputValue) => {
              updateSrfObject("linkedRanges", newInputValue);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Default Value *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("defaultValue", e.target.value);
            }}
          />
        </Grid>{" "}
      </Grid>
      <br />

      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          Save
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
