import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { useSnackbar } from "notistack";
import { ClassicTable } from "./../../utils/components/Styles";
import { useReactToPrint } from "react-to-print";
import { BASE_URL } from "./../../global";
import { selectTypeA } from "../typeA/typeASlice";
const axios = require("axios");

export default function EditTypeB(props) {
  let { datasheetReadingId, datasheetId, instrumentId, lc, nominalVal, stdRangeIds, supportiveMaster } =
    useParams();
  if (props.viewAll == true) {
    datasheetReadingId = props.datasheetReadingId;
    datasheetId = props.datasheetId;
    instrumentId = props.instrumentId;
    lc = props.lc;
    nominalVal = props.nominalVal;
    stdRangeIds = props.stdRangeIds;
    supportiveMaster = props.supportiveMaster;
  }

  const [typeBId, setTypeBId] = useState(null);
  const [customValues, setCustomValues] = useState({});
  const [derivedValues, setDerivedValues] = useState({});
  const [standardIds, setStandardIds] = useState([]);
  const [standards, setStandards] = useState([]);
  const [uncertaintyFactors, setUncertaintyFactors] = useState([]);
  const [options, setOptions] = useState([]);
  const [supportiveInstruments, setSupportiveInstruments] = useState([]);
  const [staticTables, setStaticTables] = useState(null);
  const [customRels, setCustomRels] = useState({})
  const [staticReading, setStaticReadings] = useState(null)
  const [masters, setMasters] = useState([])
  const [supportive, setSupportive] = useState([])
  
  const printComponentRef = React.useRef();
  const { enqueueSnackbar } = useSnackbar();

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const parseIds = (ids) => {
    ids = ids?.split(",");
    let sids = [];
    let subsids = [];
    for (let i = 0; i < ids?.length; i++) {
      let id = ids[i].split(":");
      sids.push(id[0]);
      subsids.push(id[1]);
    }
    setStandardIds({ standardIds: [...sids], subStandardIds: [...subsids] });

    fetchStandards({ standardIds: [...sids], subStandardIds: [...subsids] });
  };

  // api
  function fetchTypeB() {
    axios
      .get(BASE_URL + `typeB?_where=(datasheetReadingId,eq,${datasheetReadingId})`)
      .then((res) => {
        if (res.data?.length > 0) {
          setTypeBId(res.data[0].id);
          setCustomValues(JSON.parse(res.data[0].customValues) || {});
        }
      })
      .catch((err) => {
        console.error("typeB data fetching error: ", err);
      });
  }

  function fetchTypeBValues() {
    axios
      .get(
        BASE_URL +
          `datasheetStaticReadings?_where=(id,eq,${datasheetReadingId})`
      )
      .then((res) => {
        setDerivedValues(JSON.parse(res?.data[0]?.typeBValues));
        setStaticReadings(res?.data[0])
      })
      .catch((err) => {
        console.error("datasheet data fetching error: ", err);
      });
  }

  function fetchInstrument(additionalStandardIds = null) {
    axios
      .get(BASE_URL + `instruments?_where=(id,eq,${instrumentId})`)
      .then((res) => {
        parseIds(additionalStandardIds || res.data[0].standardMasterIds);
        setUncertaintyFactors(res.data[0].uncertaintyFactors?.split(","));
        fetchUncertaintyFactors(res.data[0].uncertaintyFactors);
        fetchSupportiveInstruments(res.data[0].supportiveInstrumentMasterData);
      })
      .catch((err) => {
        console.error("instrument data fetching error: ", err);
      });
  }

  function fetchDatasheet() {
    axios
      .get(BASE_URL + `datasheets?_where=(id,eq,${datasheetId})`)
      .then((res) => {
        res.data[0].additionalStandardIds =
          res.data[0].additionalStandardIds == ""
            ? null
            : res.data[0].additionalStandardIds;
        fetchInstrument(res.data[0].additionalStandardIds);
      })
      .catch((err) => {
        console.error("datasheet data fetching error: ", err);
      });
  }

  function fetchDatasheetStaticTables() {
    let url = BASE_URL;
    return axios
      .get(
        url + `datasheetStaticTables?_where=(instrumentId,eq,${instrumentId})`
      )
      .then((res) => {
        setStaticTables(res.data.length > 0 ? res.data[0]: {});
        return res;
      })
      .catch((err) => {
        console.error("datasheet static tables data fetching error: ", err);
        return err;
      });
  }

  function fetchStandards(standardIds) {
    let url = BASE_URL;
    
    // `xjoin?_join=pl.standards,_j,pr.standardRanges&_on1=(pl.id,eq,pr.standardId)&_fields=pl.standardName,pr.rangeName,pl.id,pr.id,pl.stId&_where=(pl.status,eq,1)~and(pl.id,in,${standardIds})`,

    axios
      .get(
        url +
          `standardRanges?_where=(id,in,${stdRangeIds.split("__")[0] || 0})`
      )
      .then((res) => {
        setStandards([...res.data]);
      })
      .catch((err) => {
        console.error("standard ranges data fetching error: ", err);
      });
  }

  function fetchSupportiveInstruments(supportiveInstrumentIds) {
    supportiveInstrumentIds = supportiveInstrumentIds?.split(",");
    let ids = [];
    let subids = [];
    for (let i = 0; i < supportiveInstrumentIds?.length; i++) {
      let id = supportiveInstrumentIds[i].split(":");
      ids.push(id[0]);
      subids.push(id[1]); 
    }

    // `xjoin?_join=pl.standards,_j,pr.standardRanges&_on1=(pl.id,eq,pr.standardId)&_fields=pl.standardName,pr.rangeName,pl.id,pr.id,pl.stId&_where=(pl.status,eq,1)~and(pl.id,in,${standardIds})`,
    axios
      .get(
        BASE_URL +
        `standardRanges?_where=(id,in,${supportiveMaster})`
      )
      .then((res) => {
        setSupportiveInstruments(res.data);
      })
      .catch((err) => {
        console.error("supportiveInstruments fetching error: ", err);
      });
    
      axios
      .get(
        BASE_URL +
        `standards?_where=(id,in,${ids?.toString()})&_fields=standardName,id`
      )
      .then((res) => {
        let ss = {}
        res.data.map(s => ss[s.id] = s.standardName)
        setSupportive(ss)
      })
      .catch((err) => {
        console.error("supportiveInstruments fetching error: ", err);
      });
  }

  const fetchStandardMasters = () => {
    axios
      .get(
        BASE_URL +
        `xjoin?_join=pl.standards,_j,pr.standardRanges&_on1=(pl.id,eq,pr.standardId)&_fields=pl.standardName,pr.id&_where=(pl.status,eq,1)~and(pr.id,eq,${stdRangeIds.split("__")[0]})`
      )
      .then((res) => {
        let ms = {}
        res.data.map(m => ms[m.pr_id] = m.pl_standardName)
        setMasters(ms);
      })
      .catch((err) => {
        console.error("supportiveInstruments fetching error: ", err);
      });
  }

  function fetchUncertaintyFactors(uncertaintyFactors) {
    axios
      .get(
        BASE_URL +
          `uncertainty?_where=(id,in,${uncertaintyFactors?.toString()})`
      )
      .then((res) => {
        setOptions(res.data);
      })
      .catch((err) => {
        console.error("uncertainty factors data fetching error: ", err);
      });
  }

  function submitTypeB() {
    if (!typeBId) {
      axios
        .post(BASE_URL + `typeB`, {
          datasheetReadingId: datasheetReadingId,
          customValues: JSON.stringify(customValues),
        })
        .then((res) => {
          enqueueSnackbar("Type-B updated successfully!", {
            variant: "success",
          });
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      axios
        .patch(BASE_URL + `typeB/${typeBId}`, {
          customValues: JSON.stringify(customValues),
        })
        .then((res) => {
          enqueueSnackbar("Type-B updated successfully!", {
            variant: "success",
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  React.useEffect(() => {
    fetchDatasheet();
    fetchTypeB();
    fetchTypeBValues();
    fetchDatasheetStaticTables()
    fetchStandardMasters()
  }, []);

  React.useEffect(() => {
    if (staticTables && staticReading && options.length > 0) {
      let ops = {}
      options.map(op => ops[op.id] )
      let rels = JSON.parse(staticTables.defaultConfiguration)
      rels = rels && rels.typeb && rels.typeb.relations ? Object.entries(rels.typeb.relations) : []
      let newRels = {}
      rels.map(rel => {
        newRels[rel[1]] = staticReading[rel[0]]
      })
      setCustomRels(newRels)
    }
  }, [staticReading, staticTables, options])

  const updateCustomValue = (uCount, value) => {
    setCustomValues({
      ...customValues,
      [`${uCount}`]: value,
    });
  };

  const renderFields = () => {
    let rows = [];
    let uCount = 1;
    let hasLCDisplayed = false;
    let unlinkedRangesDisplayStatus = [];

    let linked = false
    standards?.map((standard, index) => {
      for (let i = 0; i < options?.length; i++) {
        // unlinked factors should display only once
        linked = false
        if (options[i].linkedRanges === null || options[i].linkedRanges === "" ) {
          if (unlinkedRangesDisplayStatus[options[i].name] === true)
            continue;
          else {
            unlinkedRangesDisplayStatus[options[i].name] = true;
          }
        }

        // linked range value
        let dv = standard[options[i].linkedRanges];
        if (dv !== undefined) linked = true

        // default value
        if (options[i].defaultValue) {
          dv = options[i].defaultValue;
          linked = false;
        }

        // derived value from dtasheet
        if (derivedValues && derivedValues[options[i].id]) {
          dv = derivedValues[options[i].id];
          linked = false
        }

        // custom value
        if (options[i].linkedRanges === "leastCount") {
          linked = false
          if (hasLCDisplayed) continue;
          let _lc = Number((lc?.match(/[\d\.]+/g) || [])[0]);
          dv = _lc / 2;
          hasLCDisplayed = true;
        } else if (customValues["s:" + index + ":" + (i + 1)]) {
          dv = customValues["s:" + index + ":" + (i + 1)];
          dv = Number((String(dv)?.match(/[\d\.]+/g) || [])[0]);
          linked = false
        }

        dv = String(dv).replace(",", " ");

        if (customRels[options[i].id]) {
          dv = customRels[options[i].id]
          linked = false
        }


        let row = (
          <TableRow key="1">
            <TableCell>{"U" + uCount}</TableCell>
            <TableCell>
              {`${options[i].name}${linked ? `(${masters[stdRangeIds.split("__")[0]]})` : ""}`}
              <br />±{" "}
              <TextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                placeholder={`enter U${uCount}`}
                defaultValue={dv?.replaceAll("#", " ")}
                value={dv?.replaceAll("#", " ")}
                onChange={(e) =>
                  updateCustomValue(
                    "s:" + index + ":" + (i + 1),
                    e.target.value
                  )
                }
              />
            </TableCell>
            <TableCell>{options[i].distribution}</TableCell>
            <TableCell>ω</TableCell>
            <TableCell>Sensitivity Coeff. = 1</TableCell>
            <TableCell>{dv?.replaceAll("#", " ")} </TableCell>
          </TableRow>
        );
        rows.push(row);
        uCount++;
      }

      if (
        standard["percentOfOutput"] != "" &&
        standard["percentOfOutput"] != null &&
        standard["plusValue"] != "" &&
        standard["plusValue"] != null
      ) {
        let row = (
          <TableRow key="1">
            <TableCell>{"U" + uCount}</TableCell>
            <TableCell>
              {"Specification"}
              <br />±{" "}
              <TextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                placeholder={`enter U${uCount++}`}
                defaultValue={
                  (Number(standard["percentOfOutput"]) * Number(nominalVal)) /
                    100 +
                  Number(standard["plusValue"])
                }
                onChange={(e) => {}}
              />
            </TableCell>
            <TableCell>{"1/√3"}</TableCell>
            <TableCell>ω</TableCell>
            <TableCell>Sensitivity Coeff. = 1</TableCell>
            <TableCell>
              {(Number(standard["percentOfOutput"]) * Number(nominalVal)) /
                100 +
                Number(standard["plusValue"])}
            </TableCell>
          </TableRow>
        );
        rows.push(row);
      }
    });

    let parameter = ["axialUniformity", "radialUniformity", "stability"];
    supportiveInstruments.length > 0 &&
      supportiveInstruments.map((factor, index) => {
        for (let i = 0; i < parameter.length; i++) {
          if (factor[parameter[i]] != "" && factor[parameter[i]] != null) {
            let dv = factor[parameter[i]];
            if (customValues["si:" + index + ":" + (i + 1)]) {
              dv = customValues["si:" + index + ":" + (i + 1)];
            }
            let row = (
              <TableRow key="1">
                <TableCell>{"U" + uCount}</TableCell>
                <TableCell>
                  {`${parameter[i]} (${supportive[factor.standardId]})`}
                  <br />±{" "}
                  <TextField
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    placeholder={`enter U${uCount++}`}
                    defaultValue={dv?.replaceAll("#", "")}
                    onChange={(e) => {
                      updateCustomValue(
                        "si:" + index + ":" + (i + 1),
                        e.target.value
                      );
                    }}
                  />
                </TableCell>
                <TableCell>{"1/√3"}</TableCell>
                <TableCell>ω</TableCell>
                <TableCell>Sensitivity Coeff. = 1</TableCell>
                <TableCell>{factor[parameter[i]]}</TableCell>
              </TableRow>
            );
            rows.push(row);
          }
        }
        
      });
    return rows;
  };

  return (
    <Paper sx={{ mx: 5, mt: 2, p: 2 }} ref={printComponentRef}>
      <Typography variant="h5" align="center" component="div" sx={{ mb: 2 }}>
        Type-B Contribution
      </Typography>

      <div>
        <ClassicTable>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Source</TableCell>
                <TableCell>Distribution</TableCell>
                <TableCell>DOF</TableCell>
                <TableCell>Calculation</TableCell>
                <TableCell>Value(±)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{standards && options && renderFields()}</TableBody>
          </Table>
        </ClassicTable>
      </div>

      {props.viewAll != true && (
        <Toolbar
          sx={{ displayPrint: "none" }}
          style={{ padding: "0px", width: "100%" }}
        >
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0, displayPrint: "none" }}
            onClick={() => {
              submitTypeB();
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{ ml: 3, displayPrint: "none" }}
            onClick={handlePrint}
          >
            Print
          </Button>
        </Toolbar>
      )}
    </Paper>
  );
}
