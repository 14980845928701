import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";

import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { TextField, Autocomplete } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { showComponent } from "../helper/helpers";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 40 },
  { id: "companyName", label: "Company Name", minWidth: 40 },
  { id: "referenceNumber", label: "Reference Number", minWidth: 40 },
  {
    id: "date",
    label: "Quotation Date",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "totalAmount",
    label: "Total Amount",
    align: "left",
    minWidth: 40,
  },
];

function createData(
  id,
  companyName,
  referenceNumber,
  date,
  totalAmount,
  quotationNumber,
  clientId
) {
  return {
    id,
    companyName,
    referenceNumber,
    date,
    totalAmount,
    quotationNumber,
    clientId,
  };
}

export default function QuotationList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [quotationList, setQuotationList] = React.useState([]);
  const [quotationData, setQuatationData] = React.useState();
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    console.log(id);

    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    axios
      .patch(url + `instruments/${id}`, jsonData)
      .then((res) => {
        toast("instrument was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  function getClientList() {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Company Name": "companyName",
        "Reference Number": "referenceNumber",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` qt.date between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${rowsPerPage * Number(page)
        }`;
    }

    let data = {
      query: `select qt.id,qt.productId,qt.quotationNumber,qt.referenceNumber,qt.date,qt.totalAmount,client.companyName,qt.clientId from quotation as qt left join clients as client on qt.clientId = client.id ${whereConditions} order by qt.id DESC ${pagination_settings}`,
    };
    console.log(data.query);
    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        const uniqueIds = [];
        const unique = res.data.filter((element) => {
          const isDuplicate = uniqueIds.includes(element.quotationNumber);
          if (!isDuplicate) {
            uniqueIds.push(element.quotationNumber);
            return true;
          }
          return false;
        });
        // setQuotationList(unique);
        setQuotationList([...unique]);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  // api calls
  function getTotalRows() {
    axios
      .get(BASE_URL + `quotation/count`)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };
  const search = () => {
    getClientList();
  };

  useEffect(() => {
    getTotalRows();
  }, []);

  useEffect(() => {
    getClientList();
  }, [rowsPerPage, page]);

  const initiateQuotationList = () => {
    var rowDaata = [];
    for (let i = 0; i < quotationList.length; i++) {
      rowDaata.push(
        createData(
          quotationList[i].id,
          quotationList[i].companyName,
          quotationList[i].referenceNumber,
          quotationList[i].date
            ? moment(new Date(quotationList[i].date)).format("DD-MM-YYYY")
            : "",
          quotationList[i].totalAmount,
          quotationList[i].quotationNumber,
          quotationList[i].clientId
        )
      );
    }
    setQuatationData(rowDaata);
  };
  useEffect(() => {
    initiateQuotationList();
  }, [quotationList]);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Toolbar>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/quotation/createQuotation"
          onClick={() => {
            { };
          }}
        >
          <b>ADD</b>
        </Button>
      </Toolbar>
      <br />
      <div
        className="mb-2"
        style={{ display: "flex", float: "left", justifyContent: "flex-end" }}
      >
        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
            <DatePicker
              label="From"
              value={from ? new Date(from) : ''}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setFrom(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </div>

        <div style={{ marginRight: "10px" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="To"
              value={to ? new Date(to) : ''}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setTo(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
              style={{ marginRight: "20px" }}
            />
          </LocalizationProvider>
        </div>

        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
            marginLeft: "10px",
          }}
          size="small"
          id="combo-box-demo"
          options={[
            { key: "companyName", label: "Company Name" },
            { key: "referenceNumber", label: "Reference Number" },
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
          }}
        />
        <TextField
          id="outlined-basic"
          label={"enter " + searchBy}
          size="small"
          variant="outlined"
          onChange={(e) => setSearchKey(e.target.value)}
        />
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            search();
          }}
        >
          <SearchIcon />
        </Button>
      </div>
      <br />

      <table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="datatable-keytable"
        width="100%"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {quotationData &&
            quotationData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      let value = row[column.id];
                      if (column.id == "id") {
                        value = page * rowsPerPage + (index + 1);
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      <ButtonGroup size="small" aria-label="small button group">
                        <Tooltip title="Edit Quotation" placement="top-start">
                          <Button
                            component={Link}
                            to={`/quotation/editQuotation/${row.clientId}/${row.quotationNumber}`}
                          >
                            <PreviewIcon />
                          </Button>
                        </Tooltip>
                        {
                          showComponent("delete") &&
                          <Tooltip title="Delete Client" placement="top-start">
                            <Button
                              onClick={(e) => {
                                if (
                                  window.confirm("Really want to delete quotation?")
                                ) {
                                  submitSiteDelete(row.id);
                                }
                              }}
                            >
                              <DeleteIcon style={{ color: "#dc3545" }} />
                            </Button>
                          </Tooltip>
                        }
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
        </TableBody>
      </table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        component="div"
        colSpan={3}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        ActionsComponent={TablePaginationActions}
      />
      <ToastContainer />
    </TableContainer>
  );
}
