import React, { useEffect } from "react";
import { BASE_URL, BANK_DETAILS, GST_NUMBER } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import "./viewInvoice.css";
import { makeStyles } from "@material-ui/core/styles";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextareaAutosize,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import html2pdf from 'html2pdf.js'
import UnitechHeader from '../image/header.png'
import UnitechFooter from '../image/footer.png'
import moment from "moment";
const { ToWords } = require("to-words");
const axios = require("axios");
const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      border: "1px solid black",
      textAlign: "center"
    },
  },
  tableWithoutBorder: {
    "& .MuiTableCell-root": {
      border: "1px solid black",
      borderBottom: "none !important",
      paddingLeft: "5px"
    },
  },
  row: {
    height: 15,
  },
  gstCell: {
    height: 5,
    border: "none",
  },
  cell: {
    padding: 1,
    width: 180,
    height: 5,
  },
  signCell: {
    padding: 1,
    width: 30,
    alignItems: "center",
    fontStyle: "bold",
  },
  srCell: {
    padding: 1,
    width: 100,
    height: 5,
  },

  nameCell: {
    padding: 1,
    width: 250,
    height: 5,
  },
  makeCell: {
    padding: 1,
    width: 100,
    height: 5,
  },
  rangeCell: {
    padding: 1,
    width: 120,
    height: 5,
  },
  qntCell: {
    padding: 1,
    width: 40,
    height: 5,
  },
  rateCell: {
    padding: 1,
    width: 120,
    height: 5,
  },
  totalCell: {
    padding: 1,
    width: 130,
    height: 5,
  },
});
function InstrumentTable(props) {
  const toWords = new ToWords();
  let productDetails = props.productDetails;
  console.log("Product Details " + props.productDetails);
  var amounts = {
    totalAmountBeforeTax: 0,
    gst: 0,
    grandTotal: 0,
  };
  if (productDetails.length > 0) {
    for (let i = 0; i < productDetails.length; i++) {
      amounts.totalAmountBeforeTax += productDetails[i].prR_amount;
    }
    amounts.gst = (amounts.totalAmountBeforeTax * 18) / 100;
    amounts.grandTotal = amounts.gst + amounts.totalAmountBeforeTax;
    console.log(productDetails);
  }
  return (
    <Table mt={5} className={[props.classes.table, 'margin-bottom-5']}>
      <TableHead className="single-border-table">
        <TableRow>
          <TableCell className={[props.classes.srCell, 'special2']} align="center">
            <b>Sr. No</b>
          </TableCell>
          <TableCell className={[props.classes.nameCell, 'special2']} align="center">
            <b>Product Name </b>
          </TableCell>
          <TableCell className={[props.classes.makeCell, 'special2']} align="center">
            <b>Discription</b>
          </TableCell>
          <TableCell className={[props.classes.rangeCell, 'special2']} align="center">
            <b>Rate</b>
          </TableCell>
          <TableCell className={[props.classes.qntCell, 'special2']} align="center">
            <b>Quantity</b>
          </TableCell>
          <TableCell className={[props.classes.rateCell, 'special2']} align="center">
            <b>Amount</b>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody className="single-border-table">
        {productDetails.length > 0 &&
          productDetails.map((product, i) => (
            <TableRow className={props.classes.row}>
              <TableCell className={props.classes.srCell} align="center">
                {i + 1}
              </TableCell>
              <TableCell className={props.classes.nameCell} align="left">
                {product.custP_productName}
              </TableCell>
              <TableCell
                className={props.classes.nameCell}
                align="center"
                style={{ minWidth: "170px" }}
              >
                {product.prR_discription}
              </TableCell>
              <TableCell className={props.classes.rangeCell} align="center">
                {product.prR_rate.toFixed(2)}
              </TableCell>
              <TableCell className={props.classes.qntCell} align="center">
                {product.prR_quantity}
              </TableCell>
              <TableCell className={props.classes.rateCell} align="center">
                {product.prR_amount.toFixed(2)}
              </TableCell>
            </TableRow>
          ))}
        <TableRow>
          <TableCell
            className={props.classes.rateCell}
            align="left"
            colSpan={4}
          >
            <b>GST NO : {GST_NUMBER}</b>
          </TableCell>
          <TableCell className={props.classes.qntCell} align="center">
            <b>Sub Total</b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>{amounts.totalAmountBeforeTax.toFixed(2)}</b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={props.classes.rateCell}
            align="left"
            colSpan={4}
          >
            <b>
              <table style={{ border: "none" }}>
                <tr>
                  <td style={{ border: "none", minWidth: "60px", textAlign: "left", paddingLeft: "5px" }}>
                    Bank Name
                  </td>
                  <td style={{ border: "none" }}>:</td>
                  <td style={{ border: "none", textAlign: "left", paddingLeft: "5px" }}>
                    {BANK_DETAILS["Bank Name"]}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "none", minWidth: "60px", textAlign: "left", paddingLeft: "5px" }}>
                    Bank A/c. No.
                  </td>
                  <td style={{ border: "none" }}>:</td>
                  <td style={{ border: "none", textAlign: "left", paddingLeft: "5px" }}>
                    {BANK_DETAILS["Bank A/c. No."]}
                  </td>
                </tr>
                <tr style={{ border: "none" }}>
                  <td style={{ border: "none", textAlign: "left", paddingLeft: "5px" }}>IFSC Code</td>
                  <td style={{ border: "none" }}>:</td>
                  <td style={{ border: "none", textAlign: "left", paddingLeft: "5px" }}>
                    {BANK_DETAILS["IFSC Code"]}
                  </td>
                </tr>
              </table>
            </b>
          </TableCell>
          <TableCell className={props.classes.qntCell} align="center">
            {
              props.gstType == 1
                ? <>
                  <div><b>SGST</b></div>
                  <div><b>CGST</b></div>
                </>
                : props.gstType == 2
                  ? <div><b>IGST</b></div>
                  : ""
            }
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            {
              props.gstType == 1
                ? <>
                  <div><b>{(amounts.totalAmountBeforeTax * 0.09).toFixed(2)}</b></div>
                  <div><b>{(amounts.totalAmountBeforeTax * 0.09).toFixed(2)}</b></div>
                </>
                : props.gstType == 2
                  ? <div><b>{(amounts.totalAmountBeforeTax * 0.18).toFixed(2)}</b></div>
                  : ""
            }
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={props.classes.rateCell}
            align="left"
            colSpan={4}
          >
            <b>
              <table style={{ border: "none" }}>
                <tr>
                  <td style={{ border: "none", minWidth: "100px", textAlign: "left", paddingLeft: "5px" }}>
                    BILL AMOUNT :
                  </td>
                  <td style={{ border: "none", textAlign: "left", paddingLeft: "5px" }}>
                    {toWords
                      .convert(amounts.grandTotal, {
                        currency: true,
                      })
                      .toUpperCase()}
                  </td>
                </tr>
              </table>
            </b>
          </TableCell>
          <TableCell className={props.classes.qntCell} align="center">
            <b>Grand Total</b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>{amounts.grandTotal.toFixed(2)}</b>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
export default function ViewCustomInvoice() {
  const printComponentRef = React.useRef();
  const classes = useStyles();
  const params = useParams();
  const [productList, setProductList] = React.useState([]);
  const [productDetails, setProductDetails] = React.useState({});
  const [termsAndConditions, setTermAndCondition] = React.useState();
  const [invoiceData, setInvoiceData] = React.useState({});
  const [gstType, setGstType] = React.useState(2)
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });
  const getCustomReadingsArray = () => {
    axios
      .get(
        BASE_URL +
        `xjoin?_join=mainInvoice.customInvoice,_j,prR.customProductRanges,_j,custP.customProducts&_on1=(mainInvoice.id,eq,prR.customProductId)&_on2=(prR.productId,eq,custP.id)&_fields=prR.id,custP.productName,prR.id,prR.discription,prR.quantity,prR.rate,prR.amount,prR.totalAmount,prR.sgst,prR.cgst,custP.id`
      )
      .then((res) => {
        setProductDetails(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong while fetching standardRanges!");
      });
  };

  function spaceStr(n) {
    return ' '.repeat(n)
  }

  const generatePDF = () => {
    const element = printComponentRef.current
    // const element = insertBreaks(printComponentRef.current)
    html2pdf()
      .from(element)
      .set({
        margin: [150, 1, 140, 1],
        filename: `Invoice_${params.poNumber}.pdf`,
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        pagebreak: { after: 'section', mode: ['css', 'legacy'] },
        jsPDF: {
          orientation: 'portrait',
          unit: 'pt',
          format: 'a4',
        },
      })
      .toPdf()
      .get('pdf')
      .then(function (pdf) {
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(9)
          pdf.setTextColor(0, 0, 0)
          pdf.setFont('Courier')

          let headerText =
            '\n_______________________________________________________________________________________________________\n'
          headerText += spaceStr(1)
          headerText += spaceStr(101 - 'Page No.'.length)
          headerText += 'Page No.'
          headerText += '\n'
          headerText += spaceStr(1)
          headerText += spaceStr(101 - 8)
          headerText += '' + i + ' / ' + totalPages
          headerText += '\n________________________________________________________________________________________________________'
          pdf.text(headerText, 17, 105)

          // footer section
          let footerText =
            '\n\n________________________________________________________________________________________________________'
          footerText +=
            '\n\n'
          footerText += `\n\n`
          footerText += `\n     Authorised Signatory`
          footerText +=
            '\n________________________________________________________________________________________________________'

          pdf.text(footerText, 18, 690)
          // if (letterHead) {
          pdf.addImage(UnitechHeader, 'PNG', 0, 0, 615, 110)
          pdf.addImage(UnitechFooter, 'PNG', 0, 775, 595, 60)
          // }
        }
      })
      .save()
  }

  const fetchSettings = () => {
    // Terms and Condition
    let url = BASE_URL;
    axios
      .get(url + "settings?_where=(keyName,eq,Invoice Terms)&_fields=value")
      .then((res) => {
        if (res.data.length > 0)
          setTermAndCondition(res.data[0].value)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const getProductList = () => {
    let url = BASE_URL;
    axios
      .get(url + "customProducts")
      .then((res) => {
        setProductList(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };
  const getInvoiceData = () => {
    let url = BASE_URL;
    axios
      .get(
        url +
        `xjoin?_join=invoice.customInvoice,_j,client.clients&_on1=(invoice.clientId,eq,client.id)&_fields=client.companyName,invoice.termsConditions,invoice.clientId,invoice.subject,client.address,client.contact,invoice.poNumber,invoice.poDate,invoice.gstType&_where=(invoice.clientId,eq,${params.id})~and(invoice.poNumber,eq,${params.poNumber})~and(invoice.status,eq,1)`
        // `xjoin?_join=invoice.customInvoice,_j,client.clients&_on1=(invoice.clientId,eq,client.id)&_fields=client.companyName,invoice.termsConditions,invoice.clientId,invoice.subject,client.address,client.contact,invoice.poNumber,invoice.poDate,invoice.gstType,invoice.challan_no,invoice.challan_date,invoice.party_challan_no,invoice.party_challan_date&_where=(invoice.clientId,eq,${params.id})~and(invoice.poNumber,eq,${params.poNumber})~and(invoice.status,eq,1)`
      )
      .then((res) => {
        let tmp = res.data[0]
        tmp.client_contact = tmp.client_contact.length > 0
          && JSON.parse(tmp.client_contact) && JSON.parse(tmp.client_contact)[0] ? JSON.parse(tmp.client_contact)[0] : {}
        setInvoiceData(res.data[0]);
        setGstType(res.data[0].invoice_gstType)
      })
      .catch((error) => {
        toast.error("Something Went Wrong with standards!");
      });
  };
  useEffect(() => {
    getCustomReadingsArray();
    getInvoiceData();
    fetchSettings();
    getProductList();
  }, []);

  return (
    <>
      <Paper sx={{ mx: 5, mt: 2, p: 2 }} style={{ border: "1px solid" }}>
        <div className="invoice" ref={printComponentRef}>
          <div style={{ padding: "5px", margin: "5px", marginTop: "0px" }}>
            {/* <div style={{ height: "150px" }}></div> */}
            <Grid
              container
              mt={1} mb={2}
              spacing={2}
              style={{ marginLeft: "5%", marginRight: "5%" }}
            >
              <Grid md={12} className="font-link" align="center">
                <h5>
                  <b>Tax invoice</b>
                </h5>
              </Grid>
            </Grid>
            <Table
              className={[classes.tableWithoutBorder, 'margin-bottom-5']}
              style={{ borderBottom: "none !important" }}
            >
              <TableBody className="single-border-table">
                <TableRow className={classes.row}>
                  <TableCell
                    className={[classes.cell]}
                    style={{ padding: "0px", paddingLeft: "5px", width: "60%" }}
                  >
                    <TableRow className={classes.row}>
                      <TableCell className={[classes.addressCell, "noBorder"]}>
                        <div>
                          <b>M/s. : </b>
                        </div>
                        <div style={{ height: '1em' }}></div>
                      </TableCell>
                      <TableCell className={[classes.addressCell, "noBorder"]}>
                        <div><b>{invoiceData?.client_companyName}</b></div>
                        <div><b>{invoiceData?.client_address}</b></div>
                      </TableCell>
                    </TableRow>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <span>
                      <b> Invoice No. : {invoiceData?.invoice_poNumber}</b>
                      <br></br>
                    </span>
                    <span>
                      <b>Date :{moment(invoiceData?.challan_date).format("DD/MM/YYYY")}</b>
                      <br></br>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow className={classes.row}>
                <TableCell className={[classes.addressCell, "noBorder"]}>
                        <b> Contact Person :</b>
                        <span>
                          {invoiceData?.client_contact?.contactPersonName}
                        </span>
                      </TableCell>
                  <TableCell className={classes.cell}>
                    <span>
                      <b>PO No : {invoiceData?.invoice_poNumber}</b>
                      <br></br>
                    </span>
                    <span>
                      <b>P O Date :{moment(invoiceData?.invoice_poDate).format("DD/MM/YYYY")}</b>
                      <br></br>
                    </span>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <InstrumentTable
              classes={classes}
              productDetails={productDetails}
              gstType={gstType}
            ></InstrumentTable>
            <div
              style={{
                border: "1px solid black",
                borderTopWidth: "0 !important",
                paddingLeft: "10px",
              }}
            >
              <div className="font-link-footer">
                <h6>
                  <b>* Terms And Condition:-</b>
                </h6>
                <pre
                  style={{
                    fontFamily: ' Calibri, sans-serif',
                    fontSize: '10px',
                    marginBottom: '0px',
                    paddingBottom: '10px',
                    lineHeight: 1.1,
                  }}
                >
                  {invoiceData.invoice_termsAndConditions ? invoiceData.invoice_termsAndConditions : termsAndConditions}
                </pre>
              </div>
            </div>
          </div>
        </div>
      </Paper>

      <Toolbar style={{ padding: "0px", width: "100%" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ ml: 3 }}
          onClick={generatePDF}
        >
          Print
        </Button>
      </Toolbar>
    </>
  );
}
