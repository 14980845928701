import React, { useEffect } from 'react'
import { BASE_URL, ID_BASE, SRF_ID_BASE } from '../../global'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'
import * as XLSX from 'xlsx'
import { generateNumber } from './../../utils/utils'
import './srf.css'

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextareaAutosize,
  Stack,
  Input,
  Modal,
  Box,
  ButtonGroup,
  Tooltip
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { ClassicTable } from './../../utils/components/Styles'
import { showComponent } from '../helper/helpers'

const axios = require('axios')

const fixColumns = [
  {
    field: 'id',
    headerName: 'Sr. No.',
    editable: false,
  },
  {
    field: 'description',
    headerName: 'Instruments',
    editable: true,
  },
  {
    field: 'ducID',
    headerName: 'DUCID',
    editable: true,
  },
  {
    field: 'serialNumber',
    headerName: 'Serial number',
    editable: true,
  },
  {
    field: 'range',
    headerName: 'DUC Range',
    editable: true,
  },
  {
    field: 'lc',
    headerName: 'Least Count',
    editable: true,
  },
  {
    field: 'make',
    headerName: 'DUC Make',
    editable: true,
  },
  {
    field: 'model',
    headerName: 'Model',
    editable: true,
  },
  {
    field: 'conOfDuc',
    headerName: 'Con. Of Duc',
    editable: true,
  },
  {
    field: 'calibrationFrequency',
    headerName: 'Calibration frequency',
    editable: true,
  },
  {
    field: 'accuracy',
    headerName: 'Accuracy',
    editable: true,
  },
  {
    field: 'calibrationType',
    headerName: 'Calibration Type',
    editable: true,
  },
  {
    field: 'location',
    headerName: 'Location',
    editable: true,
  },
  {
    field: 'locationOfInstrument',
    headerName: 'Location Of Instrument',
    editable: true,
  },
  {
    field: 'calPoint',
    headerName: 'Calibration Point',
    editable: true,
  },
  {
    field: 'calMethod',
    headerName: 'Calibration Method',
    editable: true,
  },
  {
    field: 'labCapabilities',
    headerName: 'Lab Capabilities',
    editable: true,
  },
]

export default function CreateSRF(props) {
  const [srfObject, createSrfObject] = React.useState({
    entryDate: new Date()
  })
  var [clientArray, setClientArray] = React.useState([])
  var [instrumentArray, setInstrumentArray] = React.useState('')
  const [address, setAddress] = React.useState('')
  const [instrumentList, setInstrumentList] = React.useState([])
  const [image, setImage] = React.useState('')
  const [unitArray, setUnitArray] = React.useState([])
  const [unitFilter, setUnitFilter] = React.useState([])
  const [multiValueModalOpen, setMultiValueModalOpen] = React.useState(false)
  const [multiValueData, setMultiValueData] = React.useState({})
  const [latestId, setLatestId] = React.useState(null)
  const [latestSRFId, setLatestSRFId] = React.useState(null)
  const [serviceReqNumber, setServiceReqNumber] = React.useState(null)
  const [settingList, setSettingList] = React.useState({})
  const [userType, setUserType] = React.useState(localStorage.getItem('type'))
  const [userId, setUserId] = React.useState(localStorage.getItem('id'))
  const [copyQt, setCopyQt] = React.useState([])

  const multiValueModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
    maxHeight: '350px',
    overflow: 'auto',
  }

  const handleChange = async (e) => {
    setImage(e.target.files[0])
  }

  const getInstrumentsList = () => {
    let url = BASE_URL
    axios
      .get(url + 'instruments?_where=(status,eq,1)')
      .then((res) => {
        setInstrumentArray(res.data)
      })
      .catch((error) => {
        toast.error('Something Went Wrong!')
      })
  }

  const getClientIdList = () => {
    let url = BASE_URL
    axios
      .get(url + 'clients?_where=(status,eq,1)')
      .then((res) => {
        setClientArray(res.data)
      })
      .catch((error) => {
        toast.error('Something Went Wrong!')
      })
  }

  const initiateInstrumentList = () => {
    var pushIArray = []
    for (let i = 0; i < instrumentArray.length; i++) {
      if (instrumentArray[i].instrumentName) {
        pushIArray.push({
          label:
            instrumentArray[i].id + ', ' + instrumentArray[i].instrumentName,
          id: instrumentArray[i].id,
        })
      }
    }
    setInstrumentList(pushIArray)
  }

  var pushCArray = []
  var clientsArrays = []
  for (let i = 0; i < clientArray.length; i++) {
    if (clientArray[i].companyName) {
      pushCArray.push({
        label: clientArray[i].id + ', ' + clientArray[i].companyName,
      })
    }
    clientsArrays.push({
      id: clientArray[i].id,
      address: clientArray[i].address,
    })
  }

  const [readingColumns, setReadingColumns] = React.useState([fixColumns])
  const [extraColunmNotAdded, setExtraColunmNotAdded] = React.useState(true)

  const getExtraColumns = () => {
    // Terms and Condition
    let url = BASE_URL
    axios
      .get(
        url +
        'settings?_where=((keyName,eq,srfInstrumentsAdditionalColumns)~or(keyName,eq,Certificate Number)~or(keyName,eq,serviceReqNumber))~and(status,eq,1)&_fields=value',
      )
      .then((res) => {
        if (res.data.length > 0) {
          // let new
          if (extraColunmNotAdded) {
            setReadingColumns([
              readingColumns[0].concat(
                res.data[0].value.split(',').map((col) => {
                  return {
                    field: col,
                    headerName: col,
                    editable: true,
                  }
                }),
              ),
            ])
            setExtraColunmNotAdded(false)
          }
          setSettingList({
            'Certificate Number': res.data[1].value,
            serviceReqNumber: res.data[2].value,
          })
          updateSrfObject(
            'serviceReqNumber',
            generateNumber(res.data[2].value, `${SRF_ID_BASE}`),
          )
          setServiceReqNumber(res.data[2].value)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const [readingRows, setReadingRows] = React.useState([])

  const onChange = (e) => {
    const [file] = e.target.files
    const reader = new FileReader()

    reader.onload = (evt) => {
      const bstr = evt.target.result
      const wb = XLSX.read(bstr, { type: 'binary' })
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 })
      let rows = data.split('\n')
      if (rows.length > 2) rows.shift()
      let finalRows = []
      rows.forEach((row) => {
        let tmp = row.split(",")
        let tmp2 = ""
        let unit = ""
        tmp[3] = tmp[3].replaceAll("To", "to").split("||").map(e => {
          tmp2 = e.trim().split(" ").map(e2 => e2.trim())
          if (e.includes("to")) {
            unit = tmp2.filter((_, i) => i > 2).join(" ")
            return `${tmp2[0]}${unit ? `#${unit}` : ""}|${tmp2[2]}${unit ? `#${unit}` : ""}`
          }
          unit = tmp2.filter((_, i) => i > 0).join(" ")
          return `${tmp2[0]}${unit ? `#${unit}` : ""}`
        }).join("||")
        tmp[4] = tmp[4].split("||").map(e => {
          tmp2 = e.trim().split(" ").map(e2 => e2.trim())
          return `${tmp2[0]}${tmp2[1] ? `#${tmp2.filter((_, i) => i > 0).join(" ")}` : ""}`
        }).join("||")
        tmp[9] = tmp[9].replaceAll("±", "").split("||").map(e => {
															
																							  
          tmp2 = e.trim().split("(")
          tmp2[0] = tmp2[0].trim().split(" ")
          tmp2[0][0] = `${tmp2[0][0]} (${tmp2[1]}`
          tmp2[1] = tmp2[0][1]
          tmp2[0] = tmp2[0][0]
          // tmp2 = e.trim().split(" ").map(e2 => e2.trim())
          // return `${tmp2[0]}${tmp2[1] ? `#${tmp2.filter((_, i) => i > 0).join(" ")}` : ""}`
          return `${tmp2[0]}${tmp2[1] ? `#${tmp2.filter((_, i) => i > 0).join(" ")}` : ""}`
        }).join("||")
        tmp[10] = tmp[10].length == 0 ? null : tmp[10]
        tmp[18] = tmp[18].replaceAll("To", "to").split("||").map(e => {
          tmp2 = e.trim().split(" ").map(e2 => e2.trim())
          if (e.includes("to")) {
            unit = tmp2.filter((_, i) => i > 2).join(" ")
            return `${tmp2[0]}${unit ? `#${unit}` : ""}|${tmp2[2]}${unit ? `#${unit}` : ""}`
          }
          unit = tmp2.filter((_, i) => i > 0).join(" ")
          return `${tmp2[0]}${unit ? `#${unit}` : ""}`
        }).join("||")
        finalRows.push(tmp)
      })
      console.log(finalRows)
      setReadingRows(finalRows)
    }
    reader.readAsBinaryString(file)
  }

  // submit form
  const submitSrfInstruments = (readingRows, ids) => {
    let rows = readingRows.map((row, id) => {
      let extraCols = {}
      if (row.length > fixColumns.length - 1) {
        for (
          let i = fixColumns.length - 1;
          i < readingColumns[0].length - 1;
          i++
        ) {
          extraCols[`${readingColumns[0][i + 1].field}`] = row[i]
        }
      }
      let cols = {
        // srnNo: `${parseInt(srfObject.serviceReqNumber)}/${id + 1}`,
        srfsId: ids,
        instrumentId: row[0],
        ranges: row[3],
        lc: row[4],
        serialNumber: row[2],
        make: row[5],
        model: row[6],
        DUCID: row[1],
        ConOfDuc: row[7],
        calFrequency: row[8],
        accuracy: row[9],
        calibrationType: row[10],
        location: row[11],
        locationOfInstrument: row[12],
        calPoint: row[13],
        calMethod: row[14],
        labCapabilities: row[15],
        createdby: userId
      }
      if (extraCols !== {}) {
        cols['extraColumns'] = JSON.stringify(extraCols)
      }
      return cols
    })
    let url = BASE_URL
    return axios
      .post(url + 'srfInstruments/bulk', rows)
      .then((res) => {
        console.log('SRF Instruments created successfully!')
        return res
      })
      .catch((error) => {
        toast.error('Something Went Wrong!')
        return error
      })
  }

  const submitDatasheets = (readingRows, row, srfId) => {
    let rows = readingRows.map((row, id) => {
      let extraCols = {}
      if (row.length > fixColumns.length - 1) {
        for (
          let i = fixColumns.length - 1;
          i < readingColumns[0].length - 1;
          i++
        ) {
          extraCols[`${readingColumns[0][i + 1].field}`] = row[i]
        }
      }
      let entryDate = new Date(srfObject.entryDate)
      //let newEntryDate = new Date(entryDate.setMonth(entryDate.getDate() + row[9] ? parseInt(row[9].split(" ")[0]) : 0))
      let cols = {
        // srnNo: `100/${id + 1}`,
        srfInstrumentId: row[0],
        receiptDate: moment(srfObject.receiptDate).format('YYYY-MM-DD'),
        clientId: srfObject.clientId,
        poNumber: srfObject.poNumber,
        poDate: moment(srfObject.poDate).format('YYYY-MM-DD'),
        instrumentId: row[0],
        address: address,
        serviceReqNumber: srfObject.serviceReqNumber,
        jobNumber: `${SRF_ID_BASE + Number(srfId)}/${id + 1}`,
        ranges: row[3],
        dcNumber: srfObject.poNumber,
        DCDate: moment(srfObject.dcDate).format('YYYY-MM-DD'),
        lc: row[4],
        serialNumber: row[2],
        make: row[5],
        model: row[6],
        DUCID: row[1],
        ConOfDuc: row[7],
        accuracy: row[9],
        calibrationType: row[10],
        location: row[11],
        locationOfInstrument: row[12],
        calPoint: row[13],
        calMethod: row[14],
        createdby: userId
        // calibrationDate: moment(newEntryDate.toString()).format("YYYY-MM-DD")
      }
      if (extraCols !== {}) {
        cols['extraColumns'] = JSON.stringify(extraCols)
      }
      return cols
    })
    return axios
      .post(BASE_URL + 'datasheets/bulk', rows)
      .then((res) => {
        console.log('datasheets created successfully!')
        return res
      })
      .catch((error) => {
        toast.error('Something Went Wrong!')
        return error
      })
  }
  const submitCertificate = (readingRows, row, srfId) => {
    let rows = readingRows.map((row, id) => {
      let extraCols = {}
      if (row.length > fixColumns.length - 1) {
        for (
          let i = fixColumns.length - 1;
          i < readingColumns[0].length - 1;
          i++
        ) {
          extraCols[`${readingColumns[0][i + 1].field}`] = row[i]
        }
      }
      let cols = {
        // srnNo: `100/${id + 1}`,
        certificateNumber: generateNumber(
          settingList['Certificate Number'],
          `${SRF_ID_BASE + Number(srfId)}/`,
        ) + `${SRF_ID_BASE + Number(srfId)}/${id + 1}`,
        srfInstrumentId: row[0],
        receiptDate: moment(srfObject.receiptDate).format('YYYY-MM-DD'),
        clientId: srfObject.clientId,
        poNumber: srfObject.poNumber,
        poDate: moment(srfObject.poDate).format('YYYY-MM-DD'),
        instrumentId: row[0],
        address: address,
        serviceReqNumber: srfObject.serviceReqNumber,
        jobNumber: `${SRF_ID_BASE + Number(srfId)}/${id + 1}`,
        workOrderNumber: ID_BASE + Number(srfId) + '/WO',
        ranges: row[3],
        DCDate: moment(srfObject.dcDate).format('YYYY-MM-DD'),
        lc: row[4],
        serialNumber: row[2],
        make: row[5],
        model: row[6],
        DUCID: row[1],
        ConOfDuc: row[7],
        accuracy: row[9],
        calibrationType: row[10],
        location: row[11],
        locationOfInstrument: row[12],
        calPoint: row[13],
        calMethod: row[14],
        createdby: userId
																			 
      }
      if (extraCols !== {}) {
        cols['extraColumns'] = JSON.stringify(extraCols)
      }
      return cols
    })
    return axios
      .post(BASE_URL + 'certificates/bulk', rows)
      .then((res) => {
        console.log('Certificates created successfully!')
        return res
      })
      .catch((error) => {
        toast.error('Something Went Wrong!')
        return error
      })
  }

  const submitInvoice = (readingRows, row, srfId) => {
    let rowsd = []
    let rows = readingRows.map((row) => ({
      srfsId: srfId,
      clientId: srfObject.clientId,
      srfInstrumentId: row[0],
      instrumentId: row[0],
      poNumber: srfObject.poNumber,
      billingId: srfObject.billingId,
      poDate: srfObject.poDate
        ? moment(srfObject.poDate).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD'),
    }))

    for (let i = 0; i < readingRows.length; i++) {
      rowsd.push([readingRows[i][0]])
    }

    const newArr = []
    for (let i = 0; i < rowsd.length; i++) {
      newArr.push(rowsd[i][0])
    }

    const count = {}
    newArr.forEach((element) => {
      count[element] = (count[element] || 0) + 1
    })
    var countArray = []
    for (const key in count) {
      if (Object.hasOwnProperty.call(count, key)) {
        countArray.push(key + ':' + count[key])
      }
    }

    const newArrs = []
    for (let i = 0; i < countArray.length; i++) {
      newArrs.push({
        instrumentId: parseInt(countArray[i].split(':')[0]),
        instrumentCount: parseInt(countArray[i].split(':')[1]),
      })
    }
    var finalArray = []
    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < newArrs.length; j++) {
        if (rows[i].instrumentId == newArrs[j].instrumentId) {
          finalArray.push({
            billingId: rows[i].billingId,
            clientId: rows[i].clientId,
            instrumentId: rows[i].instrumentId,
            poDate: rows[i].poDate
              ? moment(rows[i].poDate).format('YYYY-MM-DD')
              : moment(new Date()).format('YYYY-MM-DD'),
            poNumber: rows[i].poNumber,
            srfInstrumentId: rows[i].srfInstrumentId,
            srfsId: rows[i].srfsId,
            instrumentDuplicateCount: newArrs[j].instrumentCount,
          })
        }
      }
    }
    const removeDuplicates = finalArray.filter(
      (v, i, a) =>
        a.findIndex((v2) => v2.instrumentId === v.instrumentId) === i,
    )
    return axios
      .post(BASE_URL + 'invoices/bulk', removeDuplicates)
      .then((res) => {
        console.log('Invoice created successfully!')
        return res
      })
      .catch((error) => {
        // props.setLoader(false);
        toast.error('Something Went Wrong!')
        return error
      })
  }

  var refresh = () => {
    window.location.reload(false)
  }

  const handleSubmit = (event) => {
    props.setLoader(true)
    let row = {
      clientId: srfObject.clientId,
      receiptDate: moment(srfObject.receiptDate).format('YYYY-MM-DD'),
      dcNumber: srfObject.dcNumber,
      dcDate: moment(srfObject.dcDate).format('YYYY-MM-DD'),
      entryDate: moment(srfObject.entryDate).format('YYYY-MM-DD'),
      committedDeliveryDate: moment(srfObject.committedDeliveryDate).format(
        'YYYY-MM-DD',
      ),
      serviceReqNumber: srfObject.serviceReqNumber,
      description: srfObject.description,
      address: address,
      poNumber: srfObject.poNumber,
      poDate: moment(srfObject.poDate).format('YYYY-MM-DD'),
      billingId: srfObject.billingId,
      createdby: userId
    }

    let entryDate = new Date(srfObject.entryDate)
    //let newEntryDate = new Date(entryDate.setMonth(entryDate.getDate() + (readingRows[0][8] ? parseInt(readingRows[0][8].split(" ")[0]) : 0)))

    let url = BASE_URL
    axios
      .post(url + 'srfs', row)
      .then((res) => {
        Promise.all([
          submitSrfInstruments(readingRows, res.data.insertId),
          submitDatasheets(readingRows, row, res.data.insertId),
          submitCertificate(readingRows, row, res.data.insertId),
          // submitInvoice(readingRows, row, res.data.insertId),
        ]).then((res) => {
          toast.success('SRF created successfully!')
          props.setLoader(false)
          setTimeout(refresh, 500)
        })
      })
      .catch((error) => {
        toast.error('Something Went Wrong!')
        props.setLoader(false)
      })
  }

  //ToDo: fetch cutomers from database

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    }
    newSrfObject[key] = value
    createSrfObject(newSrfObject)
  }
  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows]
    newRows[rowIndex][colIndex] = value
    setReadingRows([...newRows])
  }

  const addDescriptionRow = (tableIndex) => {
    let newReadingRows = [...readingRows]
    newReadingRows.push(Array(readingColumns[0].length - 1).fill(''))
    newReadingRows[newReadingRows.length - 1][10] = null
    setReadingRows([...newReadingRows])
    setCopyQt([...copyQt, 1])
  }

  const deleteReadingRow = (tableIndex, rowIndex) => {
    let newReadingRows = [...readingRows]
    newReadingRows.splice(rowIndex, 1)
    setReadingRows([...newReadingRows])
    copyQt.splice(rowIndex, 1)
  }

  const getInstrument = (id) => {
    for (let instrument in instrumentList) {
      if (instrumentList[instrument].id == id) return instrumentList[instrument]
    }
    return null
  }

  const getUnitMasterArray = () => {
    let url = BASE_URL
    axios
      .get(url + 'unit?_where=(status,eq,1)')
      .then((res) => {
        setUnitArray([''].concat(res.data))
      })
      .catch((error) => {
        toast.error('Something Went Wrong!')
      })
  }

  const UnitList = () => {
    var uArr = []
    for (let i = 0; i < unitArray.length; i++) {
      uArr.push({
        label: unitArray[i].id + ', ' + unitArray[i].symbol,
      })
    }
    setUnitFilter(uArr)
  }

  const parseRange = (rangeVal) => {
    let tmp = rangeVal ? rangeVal.split("||").map(e => {
      let tmp2 = e.trim().split("|").map(e2 => e2.trim())
      let tmpUnit = tmp2[0].split("#").map(e2 => e2.trim())
      tmpUnit = tmpUnit[1] ? tmpUnit[1] : ""
      tmp2[0] = tmp2[0].split("#")[0].trim()
      if (tmp2[1]) {
        tmp2[1] = tmp2[1].split("#")[0].trim()
      }
      tmp2 = tmp2.join(" to ")
      return `${tmp2}${tmpUnit.length > 0 ? ` ${tmpUnit}` : ""}`
    }) : [];
    return tmp.join(" , ")
  }

  const checkLatestInstrumentRecord = (id, sr, di, index) => {
    let url = BASE_URL;
    let where = `_where=(instrumentId,eq,${id})`
    if (sr)
      where += (where.length > 0 ? `~and(serialNumber,eq,${sr})` : `(serialNumber,eq,${sr})`)
    if (di)
      where += (where.length > 0 ? `~and(DUCID,eq,${di})` : `(DUCID,eq,${di})`)
    let limit = where.length > 0 ? "&_" : "_"
    limit += "sort=-id&_size=1"
    axios
      .get(url + `srfInstruments?${where}${limit}`)
      .then((res) => {
        // setSrfInstruments(res.data);
        let rows = [];
        if (res.data.length === 1) {
          rows = [
            res.data[0].instrumentId,
            res.data[0].DUCID,
            res.data[0].serialNumber,
            res.data[0].ranges,
            res.data[0].lc,
            res.data[0].make,
            res.data[0].model,
            res.data[0].ConOfDuc,
            res.data[0].calFrequency,
            res.data[0].accuracy,
            res.data[0].calibrationType,
            res.data[0].location,
            res.data[0].locationOfInstrument,
            res.data[0].calPoint,
            res.data[0].calMethod,
            res.data[0].labCapabilities,
          ].concat(
            res.data[0].extraColumns
              ? Object.values(JSON.parse(res.data[0].extraColumns))
              : Array(readingColumns[0].length - res.data.length).fill("")
          )
          let newRows = [...readingRows]
          newRows[index] = rows
          setReadingRows(newRows)
        }
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });


  }

  const renderMultivalueModal = () => {
    if (
      multiValueData.rowIndex != undefined &&
      multiValueData.cellIndex != undefined
    )
      return (
        <Modal
          open={multiValueModalOpen}
          onClose={() => {
            setMultiValueModalOpen(false)
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          size="md"
        >
          <Box sx={multiValueModalStyle}>
            <h5>Update Multiple values</h5>
            <table className="srf-multivalue-table">
              <tr>
                {multiValueData.cellIndex === 3 || multiValueData.cellIndex === 18 ? <th>Min Value</th> : ''}
                {multiValueData.cellIndex === 3 || multiValueData.cellIndex === 18 ? <th>{' - '}</th> : ''}
                <th>
                  {multiValueData.cellIndex === 3 || multiValueData.cellIndex === 18 ? 'Max Value' : 'Value'}
                </th>
                <th>Unit</th>
                <th></th>
              </tr>
              {readingRows[multiValueData.rowIndex][multiValueData.cellIndex]
                .split('||')
                .map((e, idx) => (
                  <tr>
                    {multiValueData.cellIndex === 3 || multiValueData.cellIndex === 18 ? (
                      <td>
                        <TextField
                          value={e.split("|")[0].split("#")[0]}
                          onChange={(e) => {
                            let ctr0 = readingRows[multiValueData.rowIndex][multiValueData.cellIndex].split("||")
                            let ctr1 = ctr0[idx].split("|")
                            let ctr2 = ctr1[0].split("#")
                            ctr2[0] = e.target.value
                            ctr1[0] = e.target.value !== "" ? ctr2.join("#") : ""
                            ctr0[idx] = ctr1.join("|")
                            updateCellValue(
                              multiValueData.rowIndex,
                              multiValueData.cellIndex,
                              ctr0.join("||"),
                            )
                          }}
                        />
                      </td>
                    ) : (
                      ''
                    )}
                    {multiValueData.cellIndex === 3 || multiValueData.cellIndex === 18 ? <td>{' - '}</td> : ''}
                    <td>
                      <TextField
                        value={
                          multiValueData.cellIndex === 3 || multiValueData.cellIndex === 18
                            ? e.split('|')[1] ? e.split('|')[1].split('#')[0] : ""
                            : e.split('#')[0]
                        }
                        onChange={(e) => {
                          let ctr0 = readingRows[multiValueData.rowIndex][multiValueData.cellIndex].split("||")
                          let ctr1 = ctr0[idx].split("|")
                          if (multiValueData.cellIndex === 3 || multiValueData.cellIndex === 18) {
                            let ctr2 = ctr1[1] ? ctr1[1].split("#") : [""]
                            ctr2[0] = e.target.value
                            ctr1[1] = ctr2.join("#")
                            ctr0[idx] = ctr1.join("|")
                          } else {
                            let ctr2 = ctr1[0].split("#")
                            ctr2[0] = e.target.value
                            ctr1[0] = ctr2.join("#")
                            ctr0[idx] = ctr1
                          }
                          updateCellValue(
                            multiValueData.rowIndex,
                            multiValueData.cellIndex,
                            ctr0.join("||"),
                          )
                        }}
                        disabled={multiValueData.cellIndex === 3 || multiValueData.cellIndex === 18 ? e.split("|")[0].split("#")[0] === "" : false}
                      />
                    </td>
                    <td>
                      <select
                        styles={{ width: '100%' }}
                        onChange={(e) => {
                          let ctr0 = readingRows[multiValueData.rowIndex][
                            multiValueData.cellIndex
                          ].split('||')
                          let ctr1 = ctr0[idx].split('|')
                          let ctr2 = ""
                          if (ctr1[0].length > 0) {
                            ctr2 = ctr1[0].split("#")
                            ctr2 = [ctr2[0], e.target.value]
                            ctr1[0] = ctr2.join("#")
                          }
                          if (ctr1.length > 1) {
                            ctr2 = ctr1[1].split("#")
                            ctr2 = [ctr2[0], e.target.value]
                            ctr1[1] = ctr2.join("#")
                          }
                          ctr0[idx] = ctr1.join("|")
                          updateCellValue(
                            multiValueData.rowIndex,
                            multiValueData.cellIndex,
                            ctr0.join('||'),
                          )
                        }}
                        value={
                          e.split("|")[0].split("#")[1] ? e.split("|")[0].split("#")[1].split("(")[0].trim() : ""
                        }
                        disabled={multiValueData.cellIndex === 3 || multiValueData.cellIndex === 18 ? e.split("|")[0].split("#")[0] === "" || !e.split("|")[1] : e === ""}
                      >
                        {unitFilter.map((option) => (
                          <option value={option.symbol}>{option.symbol}</option>
                        ))}
                      </select>
                    </td>
                    <td>
                      {readingRows[multiValueData.rowIndex][multiValueData.cellIndex].split('||').length > 1 ? (
                        showComponent("delete_in") &&
                        <DeleteIcon
                          style={{ color: '#dc3545' }}
                          onClick={(e) => {
                            let tmp = readingRows[multiValueData.rowIndex][multiValueData.cellIndex]
                              .split('||')
                              .filter((_, i) => i !== idx)
                            updateCellValue(
                              multiValueData.rowIndex,
                              multiValueData.cellIndex,
                              tmp.join('||'),
                            )
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                ))}
              <tr>
                <td colSpan={multiValueData.cellIndex === 3 || multiValueData.cellIndex === 18 ? 4 : 2}>
                  <Button
                    onClick={(e) => {
                      let ctr =
                        readingRows[multiValueData.rowIndex][
                        multiValueData.cellIndex
                        ]
                      ctr += '||'
                      updateCellValue(
                        multiValueData.rowIndex,
                        multiValueData.cellIndex,
                        ctr,
                      )
                    }}
                  >
                    <b style={{ fontSize: '18px' }}>ADD</b>
                    <AddIcon />
                  </Button>
                </td>
              </tr>
            </table>
          </Box>
        </Modal>
      )
  }

  const getNewCertId = () => {
    return axios
      .get(BASE_URL + `certificates?_sort=-id&_fields=id&_size=1`)
      .then((res) => {
        if (res.data.length < 1) setLatestId(0)
        else setLatestId(res.data[0].id)
      })
  }

  const getNewSRFId = () => {
    return axios
      .get(BASE_URL + `srfs?_sort=-id&_fields=id&_size=1`)
      .then((res) => {
        if (res.data.length < 1) setLatestSRFId(0)
        else setLatestSRFId(res.data[0].id)
      })
  }

  useEffect(() => {
    getInstrumentsList()
    getClientIdList()
    getUnitMasterArray()
    getExtraColumns()
    getNewCertId()
    getNewSRFId()
  }, [])

  useEffect(() => {
    UnitList()
  }, [unitArray])

  useEffect(() => {
    initiateInstrumentList()
  }, [instrumentArray])

  useEffect(() => {
    if (serviceReqNumber && latestSRFId) {
      updateSrfObject(
        'serviceReqNumber',
        generateNumber(serviceReqNumber, `${SRF_ID_BASE + latestSRFId}`),
      )
    }
  }, [serviceReqNumber, latestSRFId])

  if (latestId === null || latestSRFId === null || serviceReqNumber === null)
    return <div>...loading</div>

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: 'left' }}>
        Create SRF
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={pushCArray}
            renderInput={(params) => (
              <TextField {...params} label="Company Name *" />
            )}
            onInputChange={(event, newInputValue) => {
              updateSrfObject('clientId', parseInt(newInputValue.split(',')[0]))
              clientsArrays.map((client) => {
                if (client.id === parseInt(newInputValue.split(',')[0])) {
                  if (client.address) {
                    setAddress(client.address)
                  } else {
                    setAddress('')
                  }
                }
              })
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Receipt Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={srfObject.receiptDate ? srfObject.receiptDate : new Date()}
              onChange={(newValue) => {
                updateSrfObject('receiptDate', newValue)
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Dc No./RGP No. *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject('dcNumber', e.target.value)
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="DC Dt./RGP Dt. *"
              value={srfObject.dcDate ? srfObject.dcDate : new Date()}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                updateSrfObject('dcDate', newValue)
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        {
          userType == 1 ?
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Entry Date *"
                  value={srfObject.entryDate ? srfObject.entryDate : new Date()}
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  onChange={(newValue) => {
                    updateSrfObject('entryDate', newValue)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            :
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Entry Date *"
                  value={srfObject.entryDate ? srfObject.entryDate : new Date()}
                  minDate={new Date()}
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  onChange={(newValue) => {
                    updateSrfObject('entryDate', newValue)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                  disabled
                />
              </LocalizationProvider>
            </Grid>
        }

        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Committed delivery date *"
              value={
                srfObject.committedDeliveryDate
                  ? srfObject.committedDeliveryDate
                  : new Date()
              }
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                updateSrfObject('committedDeliveryDate', newValue)
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Service request number *"
            size="small"
            fullWidth
            variant="outlined"
            value={srfObject.serviceReqNumber}
            onChange={(e) => {
              updateSrfObject('serviceReqNumber', e.target.value)
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Address"
            size="small"
            disabled
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={address}
            variant="outlined"
            onChange={(e) => {
              setAddress(e.target.value)
            }}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="PO Number *"
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => {
              updateSrfObject('poNumber', e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="PO Date *"
              value={srfObject.poDate ? srfObject.poDate : new Date()}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                updateSrfObject('poDate', newValue)
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={pushCArray}
            renderInput={(params) => (
              <TextField {...params} label="Billing Name *" />
            )}
            onInputChange={(event, newInputValue) => {
              updateSrfObject(
                'billingId',
                parseInt(newInputValue.split(',')[0]),
              )
            }}
          />
        </Grid>
      </Grid>
      <br />
      <hr />
      <h4 style={{ marginBottom: '0px' }}>DUC Inward Details</h4>
      {showComponent('add_in') && (
        <Button
          style={{ float: 'left' }}
          onClick={(e) => {
            addDescriptionRow(0)
          }}
        >
          <b style={{ fontSize: '18px' }}>ADD</b>
          <AddIcon />
        </Button>
      )}
      <div style={{ width: '100%', overflow: 'auto' }}>
        <ClassicTable>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Actions</TableCell>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <TextField
                        style={{
                          width: "75px",
                          marginRight: "10px"
                        }}
                        type="number" 
                        // inputProps={{ min: 1, max: 75 }}
                        InputProps={{ inputProps: { min: 1, max: 75 } }}
                        size="small"
                        value={copyQt[index]}
                        onChange={(e) => {
                          let tmp = [...copyQt]
                          tmp[index] = Number(e.target.value)
                          setCopyQt(tmp)
                        }}
                      ></TextField>
                      <Tooltip title="Copy as New Row" placement="top-start">
                        <ContentCopyIcon
                          style={{ color: "#1976d2" }}
                          onClick={(e) => {
                            let tmp = [...readingRows]
                            tmp.splice(index + 1, 0, ...Array(copyQt[index]).fill(0).map(e => readingRows[index]) );
                            setReadingRows(tmp)
                            tmp = [...copyQt]
                            tmp.splice(index + 1, 0, ...Array(copyQt[index]).fill(0).map(e => 1))
                            setCopyQt(tmp)
                          }}
                        />
                      </Tooltip>
                      {
                        showComponent("delete_in") &&
                        <Tooltip title="Delete Row" placement="top-start">
                          <DeleteIcon
                            style={{ color: '#dc3545' }}
                            onClick={(e) => {
                              deleteReadingRow(0, index)
                            }}
                          />
                        </Tooltip>
                      }
                    </ButtonGroup>
                  </TableCell>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    if (
                      cellIndex != 0 &&
                      cellIndex != 3 &&
                      cellIndex != 4 &&
                      cellIndex != 9 &&
                      cellIndex != 10 &&
                      cellIndex != 11 &&
                      cellIndex != 8 &&
                      cellIndex != 13 &&
                      cellIndex != 14
                    ) {
                      return (
                        <TableCell>
                          <TextField
                            style={{width: "200px"}}
                            size="small"
                            value={cell}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value)
                              if (typeof readingRows[index][0] === "number" && e.target.value.length > 0 && (cellIndex == 1 || cellIndex == 2)) {
                                checkLatestInstrumentRecord(
                                  readingRows[index][0],
                                  readingRows[index][2],
                                  readingRows[index][1],
                                  index
                                )
                              }
                            }}
                          ></TextField>
                        </TableCell>
                      )
                    }
                    if (cellIndex == 0) {
                      return (
                        <TableCell style={{ width: '200px' }}>
                          <Autocomplete
                            style={{width: "200px"}}
                            defaultValue={getInstrument(cell)}
                            size="small"
                            id="combo-box-demo"
                            options={instrumentList}
                            renderInput={(params) => <TextField {...params} />}
                            onInputChange={(event, newInputValue) => {
                              var splArr = newInputValue.split(',')
                              updateCellValue(
                                index,
                                cellIndex,
                                parseInt(splArr[0]),
                              )
                              if (typeof readingRows[index][0] === "number"
                                && (readingRows[index][1].length > 0 || readingRows[index][2].length > 0)) {
                                checkLatestInstrumentRecord(
                                  readingRows[index][0],
                                  readingRows[index][2],
                                  readingRows[index][1],
                                  index
                                )
                              }
                              const unitArr = [""]
                              let desciplineH = -1
                              for (let i = 0; i < instrumentArray.length; i++) {
                                if (
                                  parseInt(newInputValue.split(",")[0]) ==
                                  instrumentArray[i].id) {
                                  desciplineH = instrumentArray[i].disciplineId
                                }
                              }
                              for (let i = 0; i < unitArray.length; i++) {
                                if (
                                  desciplineH ==
                                  unitArray[i].disciplineId
                                ) {
                                  unitArr.push(unitArray[i])
                                }
                              }
                              setUnitFilter(unitArr)
                            }}
                          />
                        </TableCell>
                      )
                    }
                    if (cellIndex == 3) {
                      return (
                        <TableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              setMultiValueData({
                                rowIndex: index,
                                cellIndex: cellIndex,
                              })
                              setMultiValueModalOpen(!multiValueModalOpen)
                            }}
                            style={{
                              backgroundColor: readingRows[index][cellIndex] && readingRows[index][cellIndex].length > 0 ? "#d9fdd3" : ""
                            }}
                          >
                            Add
                          </Button>
                        </TableCell>
                      )
                    }
                    if (cellIndex == 4) {
                      return (
                        <TableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              setMultiValueData({
                                rowIndex: index,
                                cellIndex: cellIndex,
                              })
                              setMultiValueModalOpen(!multiValueModalOpen)
                            }}
                            style={{
                              backgroundColor: readingRows[index][cellIndex] && readingRows[index][cellIndex].length > 0 ? "#d9fdd3" : ""
                            }}
                          >
                            Add
                          </Button>
                        </TableCell>
                      )
                    }
                    if (cellIndex == 9) {
                      return (
                        <TableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              setMultiValueData({
                                rowIndex: index,
                                cellIndex: cellIndex,
                              })
                              setMultiValueModalOpen(!multiValueModalOpen)
                            }}
                            style={{
                              backgroundColor: readingRows[index][cellIndex] && readingRows[index][cellIndex].length > 0 ? "#d9fdd3" : ""
                            }}
                          >
                            Add
                          </Button>
                        </TableCell>
                      )
                    }
                    if (cellIndex == 8) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              style={{width: "200px"}}
                              size="small"
                              id="combo-box-demo"
                              value={cell}
                              options={[
                                { id: 1, label: '3 Months' },
                                { id: 2, label: '6 Months' },
                                { id: 3, label: '12 Months' },
                                { id: 3, label: '18 Months' },
                                { id: 4, label: '24 Months' },
                              ]}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue === '3 Months') {
                                  updateCellValue(index, cellIndex, '3 Months')
                                  // changeCertificateStatus(3 + ":" + result.toString());
                                } else if (newInputValue === '6 Months') {
                                  updateCellValue(index, cellIndex, '6 Months')
                                  // changeCertificateStatus(4 + ":" + result.toString());
                                } else if (newInputValue === '12 Months') {
                                  updateCellValue(index, cellIndex, '12 Months')
                                } else if (newInputValue === '18 Months') {
                                  updateCellValue(index, cellIndex, '18 Months')
                                } else {
                                  updateCellValue(index, cellIndex, '24 Months')
                                }
                              }}
                            />
                          </Grid>
                        </TableCell>
                      )
                    }
                    if (cellIndex == 10) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              style={{width: "200px"}}
                              size="small"
                              id="combo-box-demo"
                              value={["NABL", "NON NABL"][cell]}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              options={[
                                { id: 1, label: 'NABL' },
                                { id: 2, label: 'NON NABL' },
                              ]}
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue === 'NABL') {
                                  updateCellValue(index, cellIndex, 0)
                                } else if (newInputValue === 'NON NABL') {
                                  updateCellValue(index, cellIndex, 1)
                                }
                              }}
                            />
                          </Grid>
                        </TableCell>
                      )
                    }
                    if (cellIndex == 11) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              style={{width: "200px"}}
                              value={cell}
                              size="small"
                              id="combo-box-demo"
                              options={[
                                { id: 1, label: 'Onsite' },
                                { id: 2, label: 'In house' },
                              ]}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onInputChange={(event, newInputValue) => {
                                // if (newInputValue === 'Onsite') {
                                //   updateCellValue(index, cellIndex, 'Onsite')
                                // } else {
                                //   updateCellValue(index, cellIndex, 'In house')
                                // }
                                updateCellValue(index, cellIndex, newInputValue)
                              }}
                            />
                          </Grid>
                        </TableCell>
                      )
                    }
                    if (cellIndex == 13) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              style={{width: "200px"}}
                              size="small"
                              id="combo-box-demo"
                              value={cell}
                              options={[
                                { id: 1, label: 'As per proc' },
                                { id: 2, label: 'As per customer' },
                              ]}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onInputChange={(event, newInputValue) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  newInputValue,
                                )
                              }}
                            />
                          </Grid>
                        </TableCell>
                      )
                    }
                    if (cellIndex == 14) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              style={{width: "200px"}}
                              size="small"
                              id="combo-box-demo"
                              values={cell}
                              options={[
                                { id: 1, label: 'Direct Method' },
                                { id: 2, label: 'Comparison Method' },
                                { id: 3, label: 'Direct/Comparison Method' },
                              ]}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onInputChange={(event, newInputValue) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  newInputValue,
                                )
                              }}
                            />
                          </Grid>
                        </TableCell>
                      )
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>
      <br />
      {showComponent('add_in') && (
        <div style={{ marginTop: '40px', display: 'flex' }}>
          <Input type="file" onChange={onChange} />
        </div>
      )}
      <Stack style={{ marginTop: '20px' }} justifyContent="start">
        <Typography align="left">Description</Typography>
        <TextareaAutosize
          minRows={3}
          onChange={(e) => {
            updateSrfObject('description', e.target.value)
          }}
          placeholder="Enter your description here"
          style={{ width: "100%", maxWidth: 900 }}
        />
      </Stack>
      <div style={{ marginTop: '20px' }}>
        <Typography align="left">
          Conformity of Statement Requirement:
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </Typography>
      </div>
      <br />
      <Toolbar style={{ padding: '0px', overflow: 'auto' }}>
        {showComponent('save') && (
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              handleSubmit()
            }}
            disabled={!srfObject.clientId}
          >
            Save
          </Button>
        )}
        {false && (
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0, ml: 2 }}
            onClick={() => {
              handleSubmit()
            }}
          >
            Print
          </Button>
        )}
      </Toolbar>
      {renderMultivalueModal()}
      <ToastContainer />
    </Paper>
  )
}
