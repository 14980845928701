//viewQuotation
import React, { useEffect } from "react";
import { BANK_DETAILS, BASE_URL } from "../../global";
import { useParams } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import html2pdf from 'html2pdf.js'
import {
  Grid,
  Paper,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextareaAutosize,
  Stack,
} from "@mui/material";
import MuiTableCell from '@material-ui/core/TableCell'
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import "./viewQuotation.css";
import PdfHeader from "../../utils/components/PdfHeader";
import { Tab } from "bootstrap";
import UnitechHeader from '../image/header.png'
import UnitechFooter from '../image/footer.png'
const axios = require("axios");
const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      border: "1px solid black",
    },
  },
  row: {
    height: 5,
  },
  cell: {
    padding: 1,
    width: 180,
    height: 5,
  },
  cell50: {
    padding: 1,
    width: '50%',
    height: 5
  },
  signCell: {
    padding: 1,
    width: 30,
    alignItems: "center",
    fontStyle: "bold",
  },
  srCell: {
    padding: 1,
    width: 100,
    height: 5,
  },

  nameCell: {
    padding: 1,
    width: 250,
    height: 5,
  },
  makeCell: {
    padding: 1,
    width: 100,
    height: 5,
  },
  rangeCell: {
    padding: 1,
    width: 120,
    height: 5,
  },
  qntCell: {
    padding: 1,
    width: 40,
    height: 5,
  },
  rateCell: {
    padding: 1,
    width: 120,
    height: 5,
  },
  totalCell: {
    padding: 1,
    width: 130,
    height: 5,
  },
  spacialCaseBorder: {
    borderBottom: "1px solid black !important"
  }
});

const TableCell2 = withStyles({
  root: {
    border: 'none',
    padding: '0px',
    margin: '0px',
    lineHeight: '1.32',
    fontSize: '14px',
    paddingLeft: '5px',
  },
})(MuiTableCell)

function InstrumentTable(props) {
  let productDeatails = props.quotationProductDetails;
  let productList = props.productList;
  console.log("Product Details " + props.quotationProductDetails);
  var amounts = {
    totalAmountBeforeTax: 0,
    gst: 0,
    sgst: 0,
    cgst: 0,
    conveyanceCharges: 0,
    grandTotal: 0,
  };

  if (productDeatails.length > 0 && productList.length > 0) {
    for (let i = 0; i < productDeatails.length; i++) {
      const result = productList.find(
        ({ id }) => id === productDeatails[i].qt_srfInstrumentId
      );
      productDeatails[i].name = result.productName;
      productDeatails[i].amount =
        productDeatails[i].qt_rate * productDeatails[i].qt_quantity;
      amounts.totalAmountBeforeTax += productDeatails[i].amount;
    }
    amounts.conveyanceCharges = parseInt(
      productDeatails[0].qt_conveyanceCharges
    );
    if (productDeatails[0].qt_gstType == 1) {
      amounts.sgst = (amounts.totalAmountBeforeTax * 9) / 100;
      amounts.cgst = (amounts.totalAmountBeforeTax * 9) / 100;
      amounts.gst = amounts.sgst + amounts.cgst;
    } else if (productDeatails[0].qt_gstType == 2) {
      amounts.gst = (amounts.totalAmountBeforeTax * 18) / 100;
    } else {
      amounts.gst = 0;
    }

    amounts.grandTotal =
      amounts.conveyanceCharges + amounts.gst + amounts.totalAmountBeforeTax;
    console.log(productDeatails);
  }
  return (
    <Table mt={5} className={["single-border-table", props.classes.table]}>
        <TableRow>
          <TableCell className={props.classes.srCell} align="center">
            <b>Sr. No</b>
          </TableCell>
          <TableCell className={props.classes.nameCell} align="center">
            <b>Product Name </b>
          </TableCell>
          <TableCell className={props.classes.makeCell} align="center">
            <b>Discription</b>
          </TableCell>
          <TableCell className={props.classes.rangeCell} align="center">
            <b>Rate</b>
          </TableCell>
          <TableCell className={props.classes.qntCell} align="center">
            <b>Quantity</b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>Amount</b>
          </TableCell>
        </TableRow>
     {productList &&
          productDeatails?.map((product, i) => (
            <TableRow className={props.classes.row}>
              <TableCell className={props.classes.srCell} align="center">
                {i + 1}
              </TableCell>
              <TableCell className={props.classes.nameCell} align="center">
                {product.name}
              </TableCell>
              <TableCell className={props.classes.makeCell} align="center">
                {product.qt_discription}
              </TableCell>
              <TableCell className={props.classes.rangeCell} align="center">
                {product.qt_rate}
              </TableCell>
              <TableCell className={props.classes.qntCell} align="center">
                {product.qt_quantity}
              </TableCell>
              <TableCell className={props.classes.rateCell} align="center">
                {product.amount}
              </TableCell>
            </TableRow>
          ))}
        <TableRow>
          <TableCell
            className={props.classes.nameCell}
            colSpan={4}
            align="center"
          >
            <b>Total Amount</b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            Taxable Amount
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>{amounts.totalAmountBeforeTax.toFixed(2)}</b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={props.classes.nameCell}
            colSpan={4}
            align="center"
          >
            {productDeatails[0]?.qt_gstType === 1 ? (
              <b>Total GST</b>
            ) : (
              <b>Total GST(CGST and SGST)</b>
            )}
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            {amounts.gst.toFixed(2)}
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>{amounts.gst.toFixed(2)}</b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={props.classes.nameCell}
            colSpan={4}
            align="center"
          >
            <b>Round Off</b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            ---
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>0</b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={props.classes.nameCell}
            colSpan={5}
            align="center"
          >
            <b>Conveyance Charges </b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b> {amounts.conveyanceCharges ? amounts.conveyanceCharges.toFixed(2) : 0}</b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={props.classes.nameCell}
            colSpan={5}
            align="center"
          >
            <b>Payable Amount</b>
          </TableCell>
          <TableCell className={props.classes.rateCell} align="center">
            <b>{amounts.grandTotal ? amounts.grandTotal.toFixed(2) : ""}</b>
          </TableCell>
        </TableRow>
    </Table>
  );
}

export default function EditQuotation() {
  const printComponentRef = React.useRef();
  const classes = useStyles();
  const params = useParams();
  const [productList, setProductList] = React.useState([]);
  const [termsAndConditions, setTermAndCondition] = React.useState();
  const [details, setDetails] = React.useState({
    companyName: "",
    companyNumber: "",
    clientName: "",
    address: "",
    referenceNumber: "",
    quotationNumber: "",
    subject: "",
    Date: "",
    thankYouForEnquiry: "",
    termsConditions: "",
    clientId: "",
    conveyanceCharges: "",
    gstNumber: ""
  });
  const [quotationProductDetails, setProductDetails] = React.useState([]);

  // const handlePrint = useReactToPrint({
  //   content: () => printComponentRef.current,
  // });

  // handlePrint

  function spaceStr(n) {
    return ' '.repeat(n)
  }

  const generatePDF = () => {
    const element = printComponentRef.current
    // const element = insertBreaks(printComponentRef.current)
    html2pdf()
      .from(element)
      .set({
        margin: [150, 1, 140, 1],
        filename: `Quotation_${params.quotationNumber}.pdf`,
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        pagebreak: { after: 'section', mode: ['css', 'legacy'] },
        jsPDF: {
          orientation: 'portrait',
          unit: 'pt',
          format: 'a4',
        },
      })
      .toPdf()
      .get('pdf')
      .then(function (pdf) {
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(9)
          pdf.setTextColor(0, 0, 0)
          pdf.setFont('Courier')

          let headerText =
            '\n_______________________________________________________________________________________________________\n'
          headerText += spaceStr(1)
          headerText += spaceStr(101 - 'Page No.'.length)
          headerText += 'Page No.'
          headerText += '\n'
          headerText += spaceStr(1)
          headerText += spaceStr(101 - 8)
          headerText += '' + i + ' / ' + totalPages
          headerText += '\n________________________________________________________________________________________________________'
          pdf.text(headerText, 17, 105)

          // footer section
          let footerText =
            '\n\n________________________________________________________________________________________________________'
          footerText +=
            '\n\n'
          footerText += `\n\n`
          footerText += `\n     Authorised Signatory`
          footerText +=
            '\n________________________________________________________________________________________________________' +

            pdf.text(footerText, 18, 690)
          // if (letterHead) {
          pdf.addImage(UnitechHeader, 'PNG', 0, 0, 615, 110)
          pdf.addImage(UnitechFooter, 'PNG', 0, 775, 595, 60)
          // }
        }
      })
      .save()
  }

  function fetchSettings() {
    axios.get(BASE_URL + `settings`).then((res) => {
      let termsAndCondition = null,
        settings = res.data;
      let _settingList = {};
      settings.forEach((setting) => {
        if (setting?.keyName?.toLowerCase() === "terms and condition") {
          termsAndCondition = setting.value;
        }
        _settingList[setting.keyName] = setting.value;
      });
      setTermAndCondition(termsAndCondition);
    });
  }
  const getProductList = () => {
    let url = BASE_URL;
    axios
      .get(url + "customProducts")
      .then((res) => {
        setProductList(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };
  const getQuotations = () => {
    let url = BASE_URL;
    axios
      .get(
        url +
        `xjoin?_join=qt.quotation,_j,client.clients&_on1=(qt.clientId,eq,client.id)&_fields=qt.id,qt.gstType,qt.productId,qt.quotationNumber,qt.referenceNumber,qt.date,qt.totalAmount,client.gstNumber,client.companyName,client.contact,client.address,client.id,qt.srfInstrumentId,qt.subject,qt.termsConditions,qt.thankYouForEnquiry,qt.conveyanceCharges,qt.rate,qt.quantity,qt.discription&_where=(qt.clientId,eq, ${params.clientId})~and(qt.quotationNumber,eq,${params.quotationNumber})`
      )
      .then((res) => {
        setProductDetails(res.data);
        let contact = JSON.parse(res.data.length > 0 && res.data[0].client_contact ? res.data[0].client_contact : {})
        let data = {
          companyName: res.data[0].client_companyName,
          companyNumber: contact[0].contactPersonName,
          clientName: contact[0].contact,
          address: res.data[0].client_address,
          referenceNumber: res.data[0].qt_referenceNumber,
          quotationNumber: res.data[0].qt_quotationNumber,
          subject: res.data[0].qt_subject,
          Date: res.data[0].qt_date,
          thankYouForEnquiry: res.data[0].qt_thankYouForEnquiry,
          termsConditions: res.data[0].qt_termsConditions,
          clientId: res.data[0].client_id,
          conveyanceCharges: res.data[0].qt_conveyanceCharges,
          gstType: res.data[0].qt_gstType,
          gstNumber: res.data[0].client_gstNumber
        };
        setDetails(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getQuotations();
    fetchSettings();
    getProductList();
  }, []);
  return (
    <>
      <Paper sx={{ mx: 5, mt: 2, p: 2 }} style={{ border: "1px solid" }}>
        <div className="invoice" ref={printComponentRef}>
          {details && termsAndConditions ? (
            <div style={{ padding: "10px", margin: "20px", marginTop: "0px", border: '1px solid black' }}>
              {/* <div style={{ height: "150px" }}></div> */}
              <Grid container mt={1} mb={2} spacing={2}>
                <Grid md={12} className="font-link" align="center">
                  <h5>
                    <b>QUOTATION</b>
                  </h5>
                </Grid>
              </Grid>
              <Table className={classes.table}>
                <TableBody className="">
                  <TableRow className={classes.row} style={{borderRightWidth: '0px'}}>
                    <TableCell2 className={classes.cell50} style={{borderRightWidth: '0px'}}>
                      <TableRow className={classes.row}>
                        <TableCell2 style={{
                          border: 'none',
                          width: '150px',
                          padding: '0px',
                        }}>
                          <b>Name of Company</b>
                        </TableCell2>
                        <TableCell2 style={{ border: 'none' }} align="center">
                          :
                        </TableCell2>
                        <TableCell2 style={{ border: 'none' }}>
                          {details.companyName}
                        </TableCell2>
                      </TableRow>
                      <TableRow className={classes.row} style={{height: '3em'}}>
                        <TableCell2 style={{
                          border: 'none',
                          width: '150px',
                          padding: '0px',
                        }}>
                          <b>Address of Company</b>
                        </TableCell2>
                        <TableCell2 style={{ border: 'none' }} align="center">
                          :
                        </TableCell2>
                        <TableCell2 style={{ border: 'none' }}>
                          {details.address}
                        </TableCell2>
                      </TableRow>
                      <TableRow className={classes.row}>
                        <TableCell2 style={{
                          border: 'none',
                          width: '150px',
                          padding: '0px',
                        }}>
                          <b>Mobile No.</b>
                        </TableCell2>
                        <TableCell2 style={{ border: 'none' }} align="center">
                          :
                        </TableCell2>
                        <TableCell2 style={{ border: 'none' }}>{details.companyNumber}</TableCell2>
                      </TableRow>
                    </TableCell2>
                    <TableCell2 className={classes.cell50}>
                      <TableRow>
                        <TableCell2 style={{
                          border: 'none',
                          width: '150px',
                          padding: '0px',
                        }}>
                          <b> Quotation number</b>
                        </TableCell2>
                        <TableCell2 align="center" style={{ border: 'none' }}>
                          :
                        </TableCell2>
                        <TableCell2 style={{ border: 'none' }}>
                          {details.quotationNumber}
                        </TableCell2>
                      </TableRow>
                      <TableRow rowSpan={2} className={classes.row}>
                        <TableCell2 style={{
                          border: 'none',
                          width: '150px',
                          padding: '0px',
                        }}>
                          <b>Date</b>
                        </TableCell2>
                        <TableCell2 style={{ border: 'none' }}  align="center">
                          :
                        </TableCell2>
                        <TableCell2 style={{ border: 'none' }}>
                          {moment(details.Date).format('DD/MM/YYYY')}
                        </TableCell2>
                      </TableRow>
                      <TableRow className={classes.row}>
                        <TableCell2 style={{
                          border: 'none',
                          width: '150px',
                          padding: '0px',
                        }}>
                          <b>GST No.</b>
                        </TableCell2>
                        <TableCell2 style={{ border: 'none' }} align="center">
                          :
                        </TableCell2>
                        <TableCell2 style={{ border: 'none' }}>
                          {details.gstNumber}
                        </TableCell2>
                      </TableRow>
                      <TableRow className={classes.row}>
                        <TableCell2 style={{
                          border: 'none',
                          width: '150px',
                          padding: '0px',
                        }}>
                          <b>Ref. No</b>
                        </TableCell2>
                        <TableCell2 style={{ border: 'none' }} align="center">
                          :
                        </TableCell2>
                        <TableCell2 style={{ border: 'none' }}>
                          {details.referenceNumber}
                        </TableCell2>
                      </TableRow>
                      <TableRow style={{height: '1em'}}>
                      </TableRow>
                    </TableCell2>
                  </TableRow>
                </TableBody>
              </Table>
              <div style={{ textAlign: "left" }}>
                Respected Sir,
                <br></br>
                <span style={{ marginLeft: "40px" }}></span>
                {details.subject}
              </div>
              <InstrumentTable
                classes={classes}
                quotationProductDetails={quotationProductDetails}
                productList={productList}
              ></InstrumentTable>
              <div style={{ marginTop: "20px" }}>
                <div className="font-link-footer">
                  <h6>
                    <b>* Terms And Condition:-</b>
                  </h6>{" "}
                  <div style={{ whiteSpace: "pre-wrap" }}>
                    {termsAndConditions}
                  </div>
                </div>
                <br></br>
                <br></br>
                <br></br>
                <h1
                  style={{
                    color: "red",
                    fontSize: "45px",
                  }}
                >
                </h1>
              </div>
            </div>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </Paper>

      <Toolbar style={{ padding: "0px", width: "100%" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ ml: 3 }}
          onClick={generatePDF}
        >
          Print
        </Button>
      </Toolbar>
    </>
  );
}
